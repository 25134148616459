.text-left {
    text-align: left;
}

body {
    font-size: 16px;
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.5rem;
}

@media only screen and (max-width: 380px) {

   body {
      font-size: 12px;
   }

    h1 {
      font-size: 2.2rem;
    }

}